import { useTranslation } from 'next-i18next';
import styles from './index.module.less';
import { CloseOutlined } from '@ant-design/icons';
import { Affix } from 'antd';
import { useConfigProvider } from '@/context/ConfigProvider';
import Link from 'next/link';
import { AppSettings } from '@/shared/app-common';
import { ga_logEvent } from '@/shared/ga';
import { EventName } from '@/shared/event-types';

const ArticleBottomAlert = () => {
  const { t } = useTranslation('common');
  const { onShowArticleBottomAlert, isShowArticleBottomAlert } = useConfigProvider();

  return (
    <>
      {isShowArticleBottomAlert && (
        <Affix offsetBottom={0} className={styles.customAffix}>
          <div className={styles.content}>
            <CloseOutlined
              className={styles.closeIcon}
              onClick={(e) => {
                e?.stopPropagation();
                onShowArticleBottomAlert(false);
                ga_logEvent(EventName.Article_Bottom_Banner_Close_Icon);
              }}
            />

            <Link
              href={AppSettings.webHost}
              onClick={() => {
                ga_logEvent(EventName.Article_Bottom_Banner_Click);
              }}
            >
              <div className={styles.contentInfo}>
                <div>{t('Your Ultimate Instagram Activity Tracker')}</div>
                <span>{t('Start tracking >')}</span>
              </div>
            </Link>
          </div>
        </Affix>
      )}
    </>
  );
};

export default ArticleBottomAlert;
